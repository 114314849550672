<template>
	<div>
		<component :is="chart_type" :chart-data="chart_data" :options="{ responsive: true, maintainAspectRatio: false }"></component>
	</div>
</template>
<script>
	import LineChart from '@/classes/line_chart.js'
	import BarChart from '@/classes/bar_chart.js'
	import TimeHelper from '@/classes/time_helper.js'
	export default {
		props: {
			dates: {
				type: Object
			},
			start_date: {
				type: String
			},
			end_date: {
				type: String
			},
			chart_type: {
				type: String,
				default: 'LineChart'
			},
		},
		name: 'date-chart',
		data(){
			return {
				time_helper: new TimeHelper(),
				chart_data: []
			}
		},
		created(){
			this.setChartData()
		},
		methods: {
			mapStats(dates){
				let stats = []
				this.getChartDates().forEach((date, index) =>  {
					for(let stat_date in dates){
						if(stat_date != date){
							continue
						}
						stats.push(dates[stat_date])
						return
					}
					stats.push(0)
				})
				return stats
			},
			getChartDates(){
				let dates = [];
				let start_date = new Date(this.start_date)
				while (start_date <= new Date(this.end_date)) {
				    dates.push(this.time_helper.moment(start_date).format('YYYY-MM-DD'))
				    start_date = this.time_helper.moment(start_date).add(1, 'days');
				}
				return dates;
			},
			getStatsTotalCount(stats){
				let count = 0
				stats.forEach(stat => {
					count += stat
				})
				return count
			},
			setChartData(){
				this.chart_data = {
				  labels: this.getDisplayDates(),
				  datasets: this.getDatasets()
				}
			},
			getDatasets(){
				let datasets = []
				for(let stat_name in this.dates){
					let stat = this.dates[stat_name]
					let data = this.mapStats(stat.data)
					datasets.push({
						label: `${this.$utils.capitalizeFirstLetter(stat_name)} (${this.getStatsTotalCount(data)})`,
						borderWidth: 1.5,
						borderColor: stat.border_color,
						backgroundColor: stat.background_color,
						data
					})
				}
				return datasets
			},
			getDisplayDates(){
				return this.getChartDates().map(date => {
					var date = new Date(date)
					return this.time_helper.moment(date).format('DD/MM') + ' (' + this.time_helper.getDayOfWeekInHebrew()[date.getDay()] + ')'
				})
			},
		},
		components: {
			LineChart,
			BarChart
		}
	}
</script>