<template>
	<div>
		<date-chart v-if="!is_loading" chart_type="BarChart" :dates="dates" :start_date="filter_params.date_from" :end_date="filter_params.date_to"></date-chart>
	</div>
</template>
<script>
	import DateChart from '@/components/charts/DateChart'
	export default {
		name: 'env-offers-chart',
		props: ['filter_params'],
		data(){
			return {
				is_loading: true,
				dates: {
					total: {
						data: [],
				      	border_color: '#2ebc4f',
				      	background_color: 'rgba(46, 188, 79, 0.3)',
					},
					drushim: {
						data: [],
				      	border_color: '#037ef3',
				      	background_color: 'rgba(3, 126, 243, 0.3)',
					},
					alljobs: {
						data: [],
				      	border_color: '#ffc715',
				      	background_color: 'rgba(255, 199, 21, 0.3)',
					},
					jobnet: {
						data: [],
						border_color: '#947600',
						background_color: 'rgba(255, 221, 87, 0.3)',
					},
					jobmaster: {
						data: [],
				      	border_color: '#ff2500',
				      	background_color: 'rgba(255, 37, 0, 0.3)',
					},
					facebook: {
						data: [],
				      	border_color: '#3b5998',
				      	background_color: 'rgba(59, 89, 152, 0.3)',
					},
					seo: {
						data: [],
				      	border_color: '#8e43e7',
				      	background_color: 'rgba(142, 67, 231, 0.3)',
					}
				}
			}
		},
		created(){
			this.init()
		},
		computed: {
			isShowAllResourceTypes(){
				return this.filter_params.resource_type == undefined
			},
		},
		methods: {
			init(){
				let outsource_class = this.filter_params.resource_type == undefined ? '' : this.filter_params.resource_type.replace('Applicant', '')
				let requests = []
				requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class }))
				if(this.isShowAllResourceTypes){
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params }))
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class: 'Drushim' }))
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class: 'Alljobs' }))
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class: 'Jobmaster' }))
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class: 'Facebook' }))
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class: 'Seo' }))
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class: 'Jobnet' }))
				}
				this.$network_utils.caller.axios.all(requests).then(response => {
					if(this.isShowAllResourceTypes){
						this.dates.total.data = response[1] 
						this.dates.drushim.data = response[2] 
						this.dates.alljobs.data = response[3] 
						this.dates.jobmaster.data = response[4] 
						this.dates.facebook.data = response[5] 
						this.dates.seo.data = response[6] 
						this.dates.jobnet.data = response[7] 
						this.stopLoading()
						return
					}
					this.dates[this.filter_params.resource_type.replace('Applicant', '').toLowerCase()].data = response[0]
					this.stopLoading()
				})
			},
			stopLoading(){
				this.is_loading = false
			}
		},
		components: {
			DateChart
		}
	}
</script>