<template>
	<div>
		<date-chart v-if="!is_loading" chart_type="BarChart" :dates="dates" :start_date="filter_params.date_from" :end_date="filter_params.date_to"></date-chart>
	</div>
</template>
<script>
	import DateChart from '@/components/charts/DateChart'
	export default {
		name: 'env-offers-chart',
		props: ['filter_params', 'env'],
		data(){
			return {
				is_loading: true,
				dates: {
					suitup: {
						data: [],
				      	border_color: '#876a67',
				      	background_color: 'rgba(135, 106, 103, 0.3)',
					},
					rebase: {
						data: [],
				      	border_color: '#2ebc4f',
				      	background_color: 'rgba(46, 188, 79, 0.3)',
					},
					office: {
						data: [],
				      	border_color: '#8e43e7',
				      	background_color: 'rgba(142, 67, 231, 0.3)',
					},
					bullrun: {
						data: [],
				      	border_color: '#0a1cbc',
				      	background_color: 'rgba(10, 28, 188, 0.3)',
					},
					piola: {
						data: [],
				      	border_color: '#8e43e7',
				      	background_color: 'rgba(142, 67, 231, 0.3)',
					}
				}
			}
		},
		created(){
			this.init()
		},
		computed: {
			isShowAllEnvs(){
				return this.filter_params.env == undefined
			},
		},
		methods: {
			init(){
				let outsource_class = this.filter_params.resource_type == undefined ? '' : this.filter_params.resource_type.replace('Applicant', '')
				let requests = []
				requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class }))
				if(this.isShowAllEnvs){
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class, env: 'Suitup' }))
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class, env: 'Rebase' }))
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class, env: 'Office' }))
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class, env: 'Bullrun' }))
					requests.push(this.$network_utils.getShuffleStats({ ...this.filter_params, outsource_class, env: 'Piola' }))
				}
				this.$network_utils.caller.axios.all(requests).then(response => {
					if(this.isShowAllEnvs){
						this.dates.suitup.data = response[1] 
						this.dates.rebase.data = response[2] 
						this.dates.office.data = response[3]
						this.dates.bullrun.data = response[4]
						this.dates.piola.data = response[5]
						this.stopLoading()
						return
					}
					this.dates[this.filter_params.env.toLowerCase()].data = response[0]
					this.stopLoading()
				})
			},
			stopLoading(){
				this.is_loading = false
			}
		},
		components: {
			DateChart
		}
	}
</script>