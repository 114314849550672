<template>
	<div class="page-width">
		<alerts></alerts>
		<div class="relative flex flex_align_center flex_justify_center">
			<div class="flex">
				<a
					@click="showLastMonthStats"
					class="button"
					:class="{ 'is-info': isShowingLastMonthDate() }"
					>Last Month</a
				>
				<div class="horizontal_space"></div>
				<a
					@click="showLastTwoWeeksStats"
					class="button"
					:class="{ 'is-info': isShowingLastTwoWeeksDate() }"
					>Last 2 Weeks</a
				>
				<div class="horizontal_space"></div>
				<a
					@click="showThisWeekStats"
					class="button"
					:class="{ 'is-info': isShowingThisWeekDate() }"
					>This Week</a
				>
				<div class="horizontal_space"></div>
				<a
					@click="showTodayStats"
					class="button"
					:class="{ 'is-info': isDateToday($route.query.date_from) }"
					>Today</a
				>
			</div>
			<div class="position_applicant_code_container">
				<div>
					<input
						v-model="position_applicant_code"
						type="text"
						class="margin_0"
						placeholder="Search position applicant code"
					/>
					<a
						@click="filterByPositionApplicantCode"
						class="button is-info"
						>Submit</a
					>
				</div>
				<link-entity
					v-if="position"
					class="position_title"
					:payload="position"
					:additional_data="{
						resource_type: 'Position',
						show_components: false,
					}"
				>
				</link-entity>
			</div>
		</div>
		<div class="vertical_space"></div>
		<div class="vertical_space"></div>
		<div class="relative flex flex_align_end flex_justify_center">
			<date-filters :filters="date_filters"></date-filters>
			<single-filters :filters="single_filters"></single-filters>
		</div>
		<div class="full_width">
			<div class="chart_container">
				<div class="chart">
					<div class="chart_title_container">
						<p class="chart_title">Offers</p>
						<p class="chart_sub_title">Envs</p>
					</div>
					<env-offers-chart
						:filter_params="getFilterParams"
					></env-offers-chart>
				</div>
				<div class="chart">
					<div class="chart_title_container">
						<p class="chart_title">Offers</p>
						<p class="chart_sub_title">Resource Types</p>
					</div>
					<resource-types-offers-chart
						:filter_params="getFilterParams"
					></resource-types-offers-chart>
				</div>
			</div>
			<div class="chart_container">
				<div class="chart">
					<div class="chart_title_container">
						<p class="chart_title">Slots</p>
						<p class="chart_sub_title">Envs</p>
					</div>
					<env-slots-chart
						:filter_params="getFilterParams"
					></env-slots-chart>
				</div>
				<div class="chart">
					<div class="chart_title_container">
						<p class="chart_title">Slots</p>
						<p class="chart_sub_title">Resource Types</p>
					</div>
					<resource-types-slots-chart
						:filter_params="getFilterParams"
					></resource-types-slots-chart>
				</div>
			</div>
			<div class="chart_container">
				<div class="chart">
					<div class="chart_title_container">
						<p class="chart_title">Priority Positions</p>
					</div>
					<div class="flex flex_justify_between" style="margin-left: 15px;">
						<div>
							<p class="bold">Last two weeks</p>
							<p v-for="(count, outsource) in last_two_weeks_priority_positions">{{ outsource }}: {{ count }}</p>
						</div>
						<div style="margin-right: 100px;">
							<p class="bold">Total ({{ total_priority_positions_count }})</p>
							<p v-for="(count, outsource) in total_priority_positions">{{ outsource }}: {{ count }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import FiltersHelper from '@/classes/filters_helper.js'
import TimeHelper from "@/classes/time_helper.js";
import DateFilters from "@/components/DateFilters";
import SingleFilters from "@/components/SingleFilters";
import EnvOffersChart from "@/components/charts/EnvOffersChart";
import ResourceTypesOffersChart from "@/components/charts/ResourceTypesOffersChart";
import EnvSlotsChart from "@/components/charts/EnvSlotsChart";
import ResourceTypesSlotsChart from "@/components/charts/ResourceTypesSlotsChart";
import LinkEntity from "@/components/LinkEntity";
import Alerts from '@/components/Alerts.vue'
export default {
	name: "dashboard",
	data() {
		let filters_helper = new FiltersHelper()
		return {
			time_helper: new TimeHelper(),
			filters_helper,
			position_applicant_code:
				this.$route.query.position_applicant_code || "",
			position: null,
			date_filters: [
				{
					designator: "date_from",
				},
				{
					designator: "date_to",
				},
			],
			single_filters: [
				{
					designator: "resource_type",
					options: [
						{
							text: "Drushim",
							value: "DrushimApplicant",
						},
						{
							text: "AllJobs",
							value: "AlljobsApplicant",
						},
						{
							text: "JobMaster",
							value: "JobmasterApplicant",
						},
						{
							text: "JobNet",
							value: "JobnetApplicant",
						},
						{
							text: "Facebook",
							value: "FacebookApplicant",
						},
						{
							text: "Seo",
							value: "SeoApplicant",
						},
					],
				},
				{
					designator: "env",
					options: filters_helper.getEnvsAsFilters(),
				},
			],
			total_priority_positions_count: undefined,
			total_priority_positions: {},
			last_two_weeks_priority_positions: {}
		};
	},
	created() {
		this.setDefaultDates();
		this.getPositionFromCode();
		this.getSlotsStats();
	},
	computed: {
		getFilterParams() {
			return {
				env: this.$route.query.env,
				resource_type: this.$route.query.resource_type,
				date_from: this.getStartDate().toUTCString(),
				date_to: this.getEndDate().toUTCString(),
				position_applicant_code: this.position_applicant_code,
			};
		},
	},
	methods: {
		setDefaultDates() {
			if (
				this.$route.query.date_from == undefined &&
				this.$route.query.date_to == undefined
			) {
				this.showThisWeekStats();
			}
		},
		getStartDate() {
			return new Date(this.$route.query.date_from);
		},
		getEndDate() {
			return new Date(this.$route.query.date_to);
		},
		showThisWeekStats() {
			this.$router.push({
				name: "dashboard",
				query: {
					...this.$route.query,
					date_from: this.time_helper
						.getLastWeek()
						.atMidnight()
						.toUTCString(),
					date_to: this.time_helper.getCurrentDate().toUTCString(),
				},
			});
		},
		showLastTwoWeeksStats() {
			this.$router.push({
				name: "dashboard",
				query: {
					...this.$route.query,
					date_from: this.time_helper
						.getLastTwoWeeks()
						.atMidnight()
						.toUTCString(),
					date_to: this.time_helper.getCurrentDate().toUTCString(),
				},
			});
		},
		showLastMonthStats() {
			this.$router.push({
				name: "dashboard",
				query: {
					...this.$route.query,
					date_from: this.time_helper
						.getLastMonth()
						.atMidnight()
						.toUTCString(),
					date_to: this.time_helper.getCurrentDate().toUTCString(),
				},
			});
		},
		showTodayStats() {
			this.$router.push({
				name: "dashboard",
				query: {
					...this.$route.query,
					date_from: this.time_helper
						.getToday()
						.atMidnight()
						.toUTCString(),
					date_to: this.time_helper
						.getToday()
						.atEndOfTheDay()
						.toUTCString(),
				},
			});
		},
		isShowingThisWeekDate() {
			return (
				this.$route.query.date_from ==
					this.time_helper
						.getLastWeek()
						.atMidnight()
						.toUTCString() &&
				this.isDateToday(this.$route.query.date_to)
			);
		},
		isShowingLastTwoWeeksDate() {
			return (
				this.$route.query.date_from ==
					this.time_helper
						.getLastTwoWeeks()
						.atMidnight()
						.toUTCString() &&
				this.isDateToday(this.$route.query.date_to)
			);
		},
		isShowingLastMonthDate() {
			return (
				this.$route.query.date_from ==
					this.time_helper
						.getLastMonth()
						.atMidnight()
						.toUTCString() &&
				this.isDateToday(this.$route.query.date_to)
			);
		},
		isDateToday(date_string) {
			return this.time_helper.isSameDate(
				new Date(date_string),
				this.time_helper.getToday()
			);
		},
		filterByPositionApplicantCode() {
			let query = { ...this.$route.query };
			query.position_applicant_code =
				this.position_applicant_code.length > 0
					? this.position_applicant_code
					: undefined;
			this.$router.push({ name: "dashboard", query });
		},
		getPositionFromCode() {
			if (this.position_applicant_code.length == 0) {
				return;
			}
			this.$network_utils
				.getPositions({
					position_applicant_code: this.position_applicant_code,
				})
				.then((response) => {
					this.position = response[0];
				});
		},
		getSlotsStats(){
			this.$network_utils.getTotalPriorityPositionsCount({ priority: 5 }).then(response => {
				this.total_priority_positions_count = response
			})
			this.$network_utils.getSlotsStats().then(response => {
				this.total_priority_positions = response
			})
			this.$network_utils.getSlotsStats({ date_from: this.time_helper.getLastTwoWeeks().atMidnight().toUTCString() }).then(response => {
				this.last_two_weeks_priority_positions = response
			})
		}
	},
	components: {
		DateFilters,
		SingleFilters,
		EnvOffersChart,
		ResourceTypesOffersChart,
		EnvSlotsChart,
		ResourceTypesSlotsChart,
		LinkEntity,
		Alerts
	},
};
</script>
<style scoped>
.chart_container {
	display: flex;
	justify-content: space-between;
	max-width: 100%;
	margin: 0 auto;
}
.chart {
	width: calc(50% - 15px);
	padding: 10px;
	padding-top: 0;
	border: 1px solid rgba(0, 0, 0, 0.1);
	background-color: white;
	box-shadow: 0 12px 18px 2px rgba(34, 0, 51, 0.04),
		0 6px 22px 4px rgba(7, 48, 114, 0.12),
		0 6px 10px -4px rgba(14, 13, 26, 0.12);
	border-radius: 13px;
	margin: 30px 0;
	margin-bottom: 0;
}
.position_applicant_code_container {
	position: absolute;
	left: 0;
	display: flex;
	justify-content: center;
}
.position_applicant_code_container .button {
	font-size: 13px;
	margin-left: 10px;
}
.chart_title_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 10px 0;
	padding: 3px 15px;
	border-radius: 30px;
}
.chart_title {
	font-weight: 900;
	font-size: 22px;
}
.chart_sub_title {
	text-transform: uppercase;
	font-size: 13px;
}
.position_title {
	margin-left: 10px;
}
.alerts_error_dot {
	position: absolute;
	top: 0;
	right: -10px;
	border: 1px solid white;
	background-color: red;
	border-radius: 50%;
	width: 10px;
	height: 10px;
}
</style>
